import React from 'react';

function TransactionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#69717E"
        stroke="#69717E"
        strokeWidth="0.3"
        d="M8.54 5.434h0-.016c-2.143.006-3.226.01-3.785.02a5.294 5.294 0 00-.53.028.512.512 0 00-.123.03.805.805 0 00-.332.323.774.774 0 00-.074.363c0 .134.017.253.073.364a.805.805 0 00.337.324c.037.014.08.022.133.029.105.012.285.02.61.026.656.012 1.933.015 4.472.02h.012c1.758.005 3.047.005 3.91 0 .432-.003.758-.006.982-.011a9.609 9.609 0 00.344-.015.36.36 0 00.065-.01M8.54 5.435l6.079 1.489h-.002M8.54 5.435l3.975-.007-.509-.513s0 0 0 0c-.396-.399-.627-.64-.75-.815a.72.72 0 01-.125-.26.592.592 0 01.003-.244m-2.594 1.84l3.026-2.403m3.05 3.893a.737.737 0 00.324-.217.75.75 0 00.186-.346h0l.003-.016a.678.678 0 00.012-.254.747.747 0 00-.162-.317c-.17-.222-.52-.575-1.192-1.252l-.057-.058h0c-.769-.77-1.18-1.18-1.448-1.37-.138-.097-.26-.152-.388-.154a.752.752 0 00-.328.091m3.05 3.893l-3.05-3.893m-.432.563a.76.76 0 01.432-.563m-.432.563s0 0 0 0l.148.028-.148-.027s0 0 0 0zM1.634 8.6a.441.441 0 01.127-.026c.047-.005.112-.009.198-.012.17-.007.432-.012.82-.015.779-.006 2.073-.006 4.185-.002h.009c2.562.004 3.85.006 4.51.017.328.005.508.013.614.026a.565.565 0 01.133.029c.041.015.067.035.083.047a.806.806 0 01.254.277c.056.11.073.23.073.363a.774.774 0 01-.073.364.806.806 0 01-.332.323.511.511 0 01-.124.03 5.265 5.265 0 01-.53.027c-.559.011-1.641.014-3.785.02H7.78h0l-3.975.007.508.514s0 0 0 0c.312.314.508.516.631.658.122.139.186.233.217.33h0l.001.004c.165.567-.37 1.105-.935.94v-.001c-.111-.033-.227-.12-.442-.318-.222-.204-.572-.55-1.156-1.133-.595-.596-.94-.945-1.14-1.161a2.56 2.56 0 01-.21-.251.444.444 0 01-.082-.183c-.069-.364.107-.731.437-.874zm0 0s0 0 0 0l.06.137-.06-.137s0 0 0 0z"
      />
    </svg>
  );
}

export default TransactionIcon;
