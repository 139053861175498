import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#69717E"
        stroke="#69717E"
        strokeWidth="0.3"
        d="M8.467 5.857h0-.014c-1.724.004-2.595.007-3.045.016a4.276 4.276 0 00-.43.022.44.44 0 00-.107.026.676.676 0 00-.282.273.651.651 0 00-.062.306c0 .11.014.212.062.306a.676.676 0 00.286.275c.034.013.071.02.115.025.086.01.232.017.494.022.527.009 1.555.011 3.597.016h.011c1.414.003 2.452.003 3.147 0 .347-.003.61-.006.79-.01a7.708 7.708 0 00.28-.011.318.318 0 00.057-.01M8.467 5.857l4.9 1.255-.001.001M8.467 5.857l3.129-.006-.36-.364s0 0 0 0c-.318-.32-.507-.515-.607-.659a.607.607 0 01-.105-.22.503.503 0 01.002-.208M8.467 5.857l2.423-1.931m2.476 3.187a.623.623 0 00.274-.183.632.632 0 00.156-.292v-.001l.003-.013a.575.575 0 00.01-.215.63.63 0 00-.137-.267c-.138-.18-.42-.465-.959-1.007l-.048-.05h0c-.618-.619-.95-.952-1.17-1.106a.588.588 0 00-.328-.129.632.632 0 00-.277.076m2.476 3.187L10.89 3.926m-.364.474c.04-.208.17-.39.364-.474m-.364.474s0 0 0 0l.148.028-.148-.027s0 0 0 0zM2.898 8.406a.382.382 0 01.11-.023c.04-.004.093-.007.161-.01.139-.005.35-.01.662-.012A403.4 403.4 0 017.2 8.36h.007c2.062.004 3.098.005 3.63.014.264.005.41.01.496.021a.482.482 0 01.115.025.676.676 0 01.286.275.651.651 0 01.062.306c0 .11-.013.211-.062.306a.676.676 0 01-.282.273.44.44 0 01-.107.026 4.28 4.28 0 01-.43.022c-.45.01-1.32.012-3.045.017h-.014 0l-3.129.005.36.364s0 0 0 0c.251.253.41.416.51.53a.818.818 0 01.18.277h0l.001.004a.641.641 0 01-.79.792h0c-.096-.029-.195-.104-.367-.263-.179-.165-.461-.443-.93-.913-.48-.478-.758-.76-.918-.935A2.086 2.086 0 012.6 9.3a.386.386 0 01-.071-.159.66.66 0 01.369-.735zm0 0s0 0 0 0l.06.138-.06-.138s0 0 0 0z"
      />
    </svg>
  );
}

export default Icon;
